import React,{Component} from 'react';
import Header from '../Header';
import './login.css'

const loginUrl = "https://butterflylogin.onrender.com/api/auth/login";


class Login extends Component {
    constructor(props){
        super(props)

        this.state={
            password:'',
            email:'',
            message:''

        }
    }

    handleSubmit = () => {
        fetch(loginUrl,{
            method:'POST',
            headers:{
                'accept':'application/json',
                'content-type':'application/json'
            },
            body:JSON.stringify(this.state)
        })

        .then((res) => res.json())
        .then((data) => {
            if(data.auth ===  false){
                this.setState({message:data.token});
            }else{
                localStorage.setItem('atk',data.token)
                
                this.props.history.push('/Settings')
            }
        })
    }
   

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    render(){
        return(
            <>
                <Header/>
                <div className="background2">
                    <center>
                   
                        <div className="formdesign2">
                            <h5>Login</h5>
                            <form className="mainform2">
                                <input type="email" className="form-control mb-3" name="email" value={this.state.email} require placeholder='Input Email' onChange={this.handleChange}/>
                                <input type="password"className="form-control mb-3" name="password" value={this.state.password} require placeholder='Input Password' onChange={this.handleChange}/>
                                
                            </form>
                        
                            <center><button className="btn btn-primary btnwidth2" onClick={this.handleSubmit}>Login</button></center>
                        </div>
                    </center>
                           
                </div>
                    
                
            </>
        )
    }
}

export default Login;