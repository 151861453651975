import React,{Component} from 'react';
import './Home.css';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Header from '../Header';




const slideUrl = "https://butterflyapi.onrender.com/homecarousel";
const youUrl = "https://butterflyapi.onrender.com/youtube";


class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        console.log (">>>Inside constructor")
        this.state={
            images:'',
            video:'',
            
        }
    }
    
    renderSlide = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <div id ="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image1} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image2} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image3} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image4} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image5} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image6} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image7} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                                                        
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
    
                    </div>
                )
            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }            
        
    }

    renderVideo = (data) =>{
        if (data){
            return data.map((item) =>{
                return(
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='react-player'
                            url= {item.video}
                            width='100%'
                            height='100%'
                            playing='true'
                            controls='true'
                            loop='true'

                        />
                    </div>
                )
            })
        }
    }
    render(){
        console.log (">>>Inside render",this.state)
        return(
            <>
                 <Header/>
                <div className="video">
                    {this.renderVideo(this.state.video)}
                </div>
                <center>
                    <div className="services">
                        <br/><h3>Our Services</h3><br/>
                        <div className="row margin">
                            <div className="col-12">
                                <i className="fa fa-cutlery fa-2x mb-4" aria-hidden="true"></i>
                                <h5>24 Hours Restaurant</h5>
                                <p className="paragr">
                                    Enjoy intercontinental dishes
                                    24 hours in restaurant and room services
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-beer fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Exclusive Bar</h5>
                                <p className="paragr">
                                    Drink to that moment and watch live games with 
                                    your friends and family
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-calendar fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Event Center</h5>
                                <p className="paragr">
                                    We have solutions to your banqueting needs in 
                                    our large capacity Halls 
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    
                </center>
            </>
            
        )
    }

    
    async componentDidMount(){
        console.log (">>> Inside didMount")
        const response = await axios.get(`${slideUrl}`)
        this.setState({images:response.data})
        const res = await axios.get(`${youUrl}`)
        this.setState({video:res.data})
      
      
    }
}

export default HomeCarousel;

