import React,{Component} from 'react';
import './Details.css';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format'; 
import Header from '../Header';






const detailsUrl = "https://angelgardensuites.herokuapp.com/rooms?details="


class RoomDetails extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside RMCOnstructor")

        this.state={
            RMdetails:'',
            startDate: new Date(),
            endDate: new Date()
            
        };
        this.checkinhandleChange = this.checkinhandleChange.bind(this);
        this.checkouthandleChange = this.checkouthandleChange.bind(this);
        
    }
    checkinhandleChange(date) {
        this.setState({
            startDate: date
        });
    }

    checkouthandleChange(date) {
        this.setState({
            endDate: date
        });
    }
       
    rendercheckinDate() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.checkinhandleChange}
                    minDate={new Date()}
                    maxDate={addDays(new Date(),26)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    
                />
            </div>
        )
    }
    
    rendercheckOutDate() {
        return (
            <div >
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.checkouthandleChange}
                    minDate={addDays(this.state.startDate,1)}
                    maxDate={addDays(new Date(),30)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                />
            </div>
        )
    }

    proceed = () => {
        
        var rmdetails = this.state.RMdetails;
        rmdetails.map((item) => {
            sessionStorage.setItem('checkin', this.state.startDate);
            sessionStorage.setItem('checkout', this.state.endDate);
            sessionStorage.setItem('images', `${item.room_images}`)
            sessionStorage.setItem('rate', `${item.room_rate}`)
            this.props.history.push(`/checkIn/${item.roomtype_Name}`)

            return 'ok'
        })
        
            
    }

   
    renderDetails = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <>
                        <div>
                            
                            <span className="Label">
                                <center>
                                    <div className="bordered">
                                        <span>From</span>&nbsp;
                                        <span style={{fontSize:"25px"}}><NumberFormat value={item.room_rate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</span> &nbsp;
                                        <span>Per Night</span>
                                    </div>
                                </center>
                                <hr style={{color:"gold"}}/>

                                <div>
                                    <div className="col-md-12 formdesign form-group">
                                        <div>
                                            <span>
                                                <label for="exampleFormControlInput1" className="form-label">
                                                    Check-In&nbsp;&nbsp;&nbsp;&nbsp;
                                                </label>
                                            </span>
                                        
                                            <span>
                                               <label>
                                                    {this.rendercheckinDate(this.state.startDate)}
                                                </label>
                                            </span>
                                            
                                        </div>
                                        <br/>
                                        <div>
                                            <span>
                                                <label for="exampleFormControlInput1" className="form-label">
                                                    Check-Out&nbsp;
                                                </label>
                                            </span>
                                            <span>
                                                <label>
                                                    {this.rendercheckOutDate(this.state.endDate)}
                                                </label> <br/>
                                            </span>
                                        </div>
                                        <br/>
                                        
                                        <div>
                                            <span>
                                                <label for="exampleFormControlInput1" className="form-label">
                                                    Adults &nbsp;
                                                </label>
                                            </span>
                                            <span>
                                                <select className="col-md-12 mb-3 reduce"  id="exampleFormControlInput1" name="Adults">
                                                    <option value="1">1 Adult</option>
                                                    <option value="2">2 Adults</option>
                                                </select>
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                <label for="exampleFormControlInput1" className="form-label">
                                                    Kids &nbsp;&nbsp;&nbsp;&nbsp;
                                                </label>
                                            </span>
                                            <span>
                                                <select className="col-md-12 reduce" name="Kids">
                                                    <option value="0">0 Kids</option>
                                                    <option value="1">1 Kids</option>
                                                </select>
                                            </span>
                                        </div><br/>
                                        <div>
                                        <center>
                                            
                                            <button className="btn butt" style={{color:"gold", fontFamily:"Georgia, 'Times New Roman', Times, serif"}}  onClick={this.proceed}>
                                                Book Now
                                            </button>
                                        
                                        </center>
                                    
                                        </div>
                                    </div>
                                    <hr style={{color:"gold"}}/>
                                    <div>
                                        <p>{this.calculate}</p>
                                    </div>

                                </div>
                                
                                        
                                    
                                
                            </span>
                                                       
                            <span>
                                <div id ="carouselExampleControls" className="carousel slide banner" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                        
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500" data-bs-pause="hover">
                                            <img key={item.roomtype_id} src={item.gallery1} className="banner" alt="rooms"/>
    
                                        </div>  
                                        <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                            <img key={item.roomtype_id} src={item.gallery2} className="banner" alt="rooms"/>
    
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                            <img key={item.roomtype_id} src={item.gallery3} className="banner" alt="rooms"/>
    
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                            <img key={item.roomtype_id} src={item.gallery4} className="banner" alt="rooms"/>
    
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                            <img key={item.roomtype_id} src={item.gallery5} className="banner" alt="rooms"/>
    
                                        </div>                                                             
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
    
                                </div>
                            
                            </span>
                            
                            <div className="features col-md-12"><br/>
                                <center><p>{item.roomtype_Name}</p></center>
                            </div>
                            
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"  aria-expanded="false" aria-controls="flush-collapseOne">
                                            <b>Room Details</b>
                                        </button>
                                    </h5>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <span>
                                                <div className="card col-md-3 rcard">
                                                    <img src={item.facility_Image2} className="card-img-top icard" alt={item.facilty1}/>
                                                    <center><h5 style={{marginTop:"-24px"}} >{item.facilty1}</h5></center>
                                                        
                                                </div>
                                            </span>
                                            <span>
                                                <div className="card col-md-3 rcard">
                                                    <img src={item.facility_Image3} className="card-img-top jcard" alt={item.facilty2}/>
                                                    <center><h5 style={{marginTop:"-19px"}} >{item.facilty2}</h5></center>
                                                        
                                                </div>
                                            </span>
                                            <span>
                                                <div className="card col-md-3 rcard">
                                                    <img src={item.facility_Image4} className="card-img-top kcard" alt={item.facilty3}/>
                                                    <center><h5 style={{marginTop:"-6px"}} >{item.facilty3}</h5></center>
                                                        
                                                </div>
                                            </span>
                                            <span>
                                                <div className="card col-md-3 rcard">
                                                    <img src={item.facility_Image5} className="card-img-top lcard" alt={item.facilty4}/>
                                                    <center><h5 style={{marginTop:"-10px"}} >{item.facilty4}</h5></center>
                                                        
                                                </div>
                                            </span>
                                            <span>
                                                <div className="card col-md-3 rcard">
                                                    <img src={item.facility_Image6} className="card-img-top mcard" alt={item.facilty5}/>
                                                    <center><h5 style={{marginTop:"-25px"}} >{item.facilty5}</h5></center>
                                                        
                                                </div>
                                            </span>
                                                                                                                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            <b>Description</b>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>{item.Description}</p>
                                            
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            <b>Extras</b>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <ul class="list-group">
                                                <li class="list-group-item"><i className="fa fa-bed text-primary"></i>&nbsp;&nbsp;{item.Extras1}</li>
                                                <li class="list-group-item"><i className="fa fa-bath text-primary"></i>&nbsp;&nbsp;{item.Extras2}</li>
                                                <li class="list-group-item"><i className="fa fa-thermometer-half text-primary"></i>&nbsp;&nbsp;{item.Extras3}</li>
                                                <li class="list-group-item"><i className="fa fa-glass text-primary"></i>&nbsp;&nbsp;{item.Extras4}</li>
                                                <li class="list-group-item"><i className="fa fa-shield text-primary"></i>&nbsp;&nbsp;{item.Extras5}</li>
                                                <li class="list-group-item"><i className="fa fa-video-camera text-primary"></i>&nbsp;&nbsp;{item.Extras6}</li>
                                                <li class="list-group-item"><i className="fa fa-lock text-primary"></i>&nbsp;&nbsp;{item.Extras7}</li>
                                                <li class="list-group-item"><i className="fa fa-car text-primary"></i>&nbsp;&nbsp;{item.Extras8}</li>
                                            </ul>
                                            
                                        </div>
                                    </div>

                                

                                </div>
                            </div>

                           
                        </div>
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }

    render(){
        console.log (">>> Inside RMdetails", this.state)
        return(
            <>
                <Header/>
                <div className="roomsheader">
                    {this.renderDetails(this.state.RMdetails)}
                    
                </div>
                
            
            </>
            
        )
    }

    async componentDidMount(){
        console.log (">>> Inside RMdidMount")
        const rmID = this.props.match.params.id;
        const response = await axios.get(`${detailsUrl}${rmID}`)
        this.setState({RMdetails:response.data})
      
      
    }
}

export default RoomDetails;

