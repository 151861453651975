import React,{Component} from 'react';
import './Reservation.css';
import Header from '../Header';

const contUrl = "https://butterflyapi.onrender.com/contact"

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:'',
            email:'',
            phone:'',
            Address:'',
            Subject:'',
            Message:'',

        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    async handleSubmit() {
        
        try {
            let result = await fetch(contUrl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    this.state
                )
            });
            
            console.log('result>  ' + result)
            alert("Message Submitted Successfully")
            this.props.history.push('/')   
            
            
        } catch(e) {
            console.log(e)
        }
    }

    render(){                                                                                
        return (
            <>
                 <Header/>
                <div className="container">
                <div className="row">
                    <b><center><div className="meeetingtitle">Contact Us</div></center></b>
                    <div className="col-5 halldiv">
                       <p style={{marginTop: "280px"}}> Contact</p><p style={{marginTop: "-32px"}}>Us</p>
                    </div>
                    <div className="col-7 imgdiv">
                        <img src="https://i.ibb.co/bgdTj92/IMG-2259-JPG.jpg" className="imgdiv" alt="hall"/>
                    </div>
                </div>
                    <div className="row">
                        <div className ="col-6 aboutfmt2">
                            <p className="formttxt4">No. 17 Borno Road, Off Independence Way, Marafa, Kaduna.</p>
                            <p className="formttxt5">+234 811 941 3556 | +234 811 941 3559 | +234 908 288 4383 </p>
                        </div>
                        <div className ="col-6 aboutfmt">
                            <input type="text" className="form-control mb-3" name="name" require placeholder= "First Name" value={this.state.name} onChange={this.handleChange}/>
                            <input type="email" className="form-control mb-3" name="email" require placeholder= "Last Name" value={this.state.email} onChange={this.handleChange}/>
                            <input type="number" className="form-control mb-3" name="phone" require placeholder= "Phone Number" value={this.state.phone} onChange={this.handleChange}/>
                            <input type="text" className="form-control mb-3" name="Address" require placeholder= "Enter Address" value={this.state.Address} onChange={this.handleChange}/>
                            <input type="text" className="form-control mb-3" name="Subject" require placeholder= "Enter Subject" value={this.state.Subject} onChange={this.handleChange}/>
                            <textarea className="form-control mb-3" rows="4" name="Message" require placeholder= "Enter Message" value={this.state.Message} onChange={this.handleChange}/>
                            <center><button type="button" class="btn btn-secondary" onClick={ () => this.handleSubmit() }>Submit</button></center>
                        </div>
                                    
                    </div>
                </div>
            
            </>
        )
    }

}
export default Contact;