import React,{Component} from 'react';
import './Gallery.css';
import Header from '../Header';

const pixUrl = "https://butterflyapi.onrender.com/hotelpics";


class Explore extends Component {
    
    constructor(props){
        super(props)
        console.log ('>>> Inside ExConstructor', props)

        this.state={
            hotelGallery:''
        }        

    }

    
    renderGallery =(data) => {
        if(data){
            return data.map((item, index) => {
                return(
                    <>
                        <div>
                            <div key={index}>
                                <img src={item.hotelpix} className="gallerypix" style={{width:"100%"}} alt={item.hotelp_id}/>
                            </div>
                        </div>
                        
                    </>
                )
            })
        }
            
    }

    render(){
        console.log (">>> Inside Exdetails", this.state)
        return(
            <div>
                <Header/>
                <div className="row edit2">
                   <center><h4>Explore Butterfly Garden Suites</h4></center>
                </div>
                <div className=" container collection edit">
                    <div className= "gallerypix">
                        {this.renderGallery(this.state.hotelGallery)}
                    </div>
                </div>
                
            </div>
        )
    }

    //api call onload
    componentDidMount(){
        console.log (">>> Inside ExDidMount", this.state)
        fetch(pixUrl, {method: 'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({hotelGallery:data})
        })
    }

}

export default Explore;


